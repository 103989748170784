@use "variables";
@use "mixins";

:root.default-dark {
  @include mixins.spread-map(variables.$theme-map-default-dark);
}



:root.theme-transition,
:root.theme-transition * {
  transition: background-color variables.$theme-transition-duration;
  transition-delay: 0s;
}
